.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #282c34;
  color: white;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timestamp-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}

.timestamp-item {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.timestamp-item p {
  margin: 0;
  padding: 0 10px;
  font-size: 18px;
}

button {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #61dafb;
}
